<template>
  <v-container fluid>
  </v-container>
</template>

<script>

import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import axios from 'axios'

export default {
  data: () => ({
    totalSalary: 0,
    totalLoan: 0,
    datesalary: null,
    salaryArray: [],
    margenSubtitle: null,
    reportTitle: null,
    mesliquida: null,
    employee: '',
    paymentDate: '',
    employeeDNI: '',
    idSalary: '',
    loanArray: [],
    morningCheckin: [],
    eveningCheckin: [],
    totalmorningCheckin: 0,
    totaleveningCheckin: 0,

  }),

  // watch: {
  //   dialogDelete(val) {
  //     val || this.closeDelete()
  //   },
  // },

  async mounted() {
    // await this.generarReporte()
    this.getReportSalary()
    if (this.$route.params.ruta === 'salary') {
      this.$router.push({ name: 'abmsalary' })
    } else {
      this.$router.push('salary')
    }
  
  },
  methods: {

    sumarrays() {
      this.totalmorningCheckin = 0
      this.totaleveningCheckin = 0
      this.totalSalary = 0
      this.totalLoan = 0

      this.morningCheckin.forEach(element => {
        this.totalmorningCheckin += parseFloat(element.amount)
      })
      this.eveningCheckin.forEach(element => {
        this.totaleveningCheckin += parseFloat(element.amount)
      })

      this.loanArray.forEach(element => {
        this.totalLoan += parseFloat(element.amount)
      })

      this.salaryArray.forEach(element => {
        this.totalSalary += parseFloat(element.amount)
      })
    },

    async generarReporte() {
    //   const doc = new jsPDF({
    //     orientation: "portrait",
    //     unit: "px",
    //     //format: [4, 2],
    //     format: "A4",
    //     margin: { top: 20 },
    //   });

      const doc = new jsPDF('p', 'pt')

      const logo = require('../../assets/images/logos/efectivo-ya-png.png')

      const imgLogo = new Image()
      imgLogo.src = logo
      doc.addImage(imgLogo, 'PNG', 40, 40, 100, 40)
      doc.setFontSize(18)
      const titulo = `Liquidación Mensual Nro.: ${this.idSalary}`
      const empleado = `Personal: ${this.employee}`
      const mesLiquidado = `Mes Liquidado: ${this.mesliquida}`

      doc.setLineWidth(0.5)
      doc.line(20, 90, 580, 90)
      doc.text(titulo, doc.internal.pageSize.width * 0.07, 120, {
        aling: 'left',

      })
      doc.text(empleado, doc.internal.pageSize.width * 0.07, 150, {
        aling: 'left',

      })
      doc.text(mesLiquidado, doc.internal.pageSize.width * 0.07, 180, {
        aling: 'left',

      })

      doc.setFontSize(12)
      const espacioEntreLineas = 20

      const fechaPago = this.paymentDate

      doc.text(
        fechaPago,
        doc.internal.pageSize.width * 0.8,
        40 + espacioEntreLineas * 1,
        {
          aling: 'right',

        },
      )

      // doc.setFillColor(240, 240, 240)
      // doc.rect(
      //   doc.internal.pageSize.width * 0.05,
      //   5,
      //   900,
      //   13,
      //   'F',
      // )

      const loanArray = Object(this.loanArray).map(obj => {
        const datos = [
          obj.loanDate,
          obj.amount,

        ]

        return datos
      })

      const salaryArray = Object(this.salaryArray).map(obj => {
        const datos = [
          obj.description,
          obj.amount,

        ]

        return datos
      })

      const fichadaArrayMorning = Object(this.morningCheckin).map(obj => {
        const datos = [
          obj.date,
          obj.day,
          obj.details,
          obj.amount,

        ]

        return datos
      })

      const fichadaArrayEvening = Object(this.eveningCheckin).map(obj => {
        const datos = [
          obj.date,
          obj.day,
          obj.details,
          obj.amount,

        ]

        return datos
      })

      doc.text('Adelantos', doc.internal.pageSize.width * 0.07, 210 + 20)

      doc.autoTable({
        // html: '#my-table',
        head: [
          ['Fecha y Hora', 'Monto'],
        ],
        body: loanArray,
        startY: 240,
        foot: [['', `Total: $ ${this.totalLoan}`]],
        showFoot: 'lastPage',
        columnStyles: {
          1: { halign: 'right' },
          2: { halign: 'right' },

        // etc
        },
        headStyles: { halign: 'center' },
        theme: 'grid', // tenemos grid, striped o plain por defecto es strpied
        tableWidth: 515,
        footStyles: { halign: 'right', fillColor: 140 },
        margin: { top: 0, bottom: 0 },
      })
      const finalY1 = doc.previousAutoTable.finalY

      // doc.autoTable({

      //   body: [['Fichada Turno Mañana']],
      //   bodyStyles: { fontStyle: 'bold', fontSize: 16 },
      //   theme: 'plain', // tenemos grid, striped o plain por defecto es strpied
      //   aling: 'right',
      //   margin: { top: 200 },

      // })

      // Agregar texto debajo de la tabla
      doc.text('Fichada Turno Mañana', doc.internal.pageSize.width * 0.07, finalY1 + 20)

      doc.autoTable({
        // html: '#my-table',
        head: [
          ['Fecha y Hora', 'Día', 'Detalle', 'Monto'],
        ],
        body: fichadaArrayMorning,
        startY: finalY1 + 30,
        foot: [['', '', '', `Total: $ ${this.totalmorningCheckin}`]],
        showFoot: 'lastPage',

        columnStyles: {
          1: { halign: 'right' },
          2: { halign: 'right' },
          3: { halign: 'right' },
          4: { halign: 'right' },

          // etc
        },
        bodyStyles: {},
        headStyles: { halign: 'center' },
        theme: 'grid', // tenemos grid, striped o plain por defecto es strpied
        tableWidth: 515,
        footStyles: { halign: 'right', fillColor: 140 },
      })

      const finalY2 = doc.previousAutoTable.finalY

      doc.text('Fichada Turno Tarde', doc.internal.pageSize.width * 0.07, finalY2 + 20)

      doc.autoTable({
        // html: '#my-table',
        head: [
          ['Fecha y Hora', 'Día', 'Detalle', 'Monto'],
        ],
        body: fichadaArrayEvening,
        foot: [['', '', '', `Total: $ ${this.totaleveningCheckin}`]],
        showFoot: 'lastPage',
        startY: finalY2 + 30,
        columnStyles: {
          1: { halign: 'right' },
          2: { halign: 'right' },
          3: { halign: 'right' },
          4: { halign: 'right' },

          // etc
        },
        bodyStyles: {},
        headStyles: { halign: 'center' },
        theme: 'grid', // tenemos grid, striped o plain por defecto es strpied
        tableWidth: 515,
        footStyles: { halign: 'right', fillColor: 140 },
      })

      const finalY3 = doc.previousAutoTable.finalY

      doc.text('Resumen de la Liquidación', doc.internal.pageSize.width * 0.07, finalY3 + 20)

      doc.autoTable({
        // html: '#my-table',
        head: [
          ['Concepto', 'Monto'],
        ],
        body: salaryArray,
        foot: [['', `Total: $ ${this.totalSalary}`]],
        columnStyles: {
          1: { halign: 'right' },
          2: { halign: 'right' },
          3: { halign: 'right' },
          4: { halign: 'right' },

        // etc
        },
        footStyles: { halign: 'right', fillColor: 140 },
        headStyles: { halign: 'center' },
        startY: finalY3 + 30,
        theme: 'striped', // tenemos grid, striped o plain por defecto es strpied
        tableWidth: 515,
        aling: 'right',
        margin: { top: 160 },
        setFont: 'Arial',

      })

      const finalY4 = doc.previousAutoTable.finalY

      // Establecer los textos a agregar al PDF
      const nombrempleado = this.employee
      const documento = `Documento ${this.employeeDNI}`

      // Obtener la longitud de cada texto en pixeles
      const text1Width = doc.getTextWidth(nombrempleado)
      const text2Width = doc.getTextWidth(documento)

      // Obtener el ancho del PDF en pixeles
      const pdfWidth = doc.internal.pageSize.getWidth()

      // Establecer la posición X de cada texto
      const text1X = pdfWidth - text1Width - 50
      const text2X = pdfWidth - text2Width - 55

      // Agregar los textos al PDF
      doc.text(text1X, finalY4 + 100, nombrempleado)
      doc.text(text2X, finalY4 + 120, documento)

      // Agregar una línea al PDF
      doc.setLineWidth(0.5)
      doc.line(pdfWidth - 40, finalY4 + 80, 300, finalY4 + 80)

      doc.save(`liquidacion ${this.employee}.pdf`, false)
    },
    async getReportSalary() {
      this.url = `${this.$store.getters.urlBase}/api/Salary/SalaryReport`
      const options = {
        headers: {
          'content-type': 'application/json',
        },
        params: {
          salaryId: this.$route.params.salaryId,

        },

        url: this.url,
      }
      await axios
        .get(this.url, options)
      // eslint-disable-next-line no-return-assign
        .then(response => {
          console.log(response.data)
          this.idSalary = response.data.salary[0].salaryId
          this.datesalary = response.data.salary[0].date
          this.mesliquida = this.moment((response.data.salary[0].date)).format('MMMM [de] YYYY')
          this.loanArray = response.data.paidLoans
          this.employee = `${response.data.employee.name} ${response.data.employee.surname}`
          this.employeeDNI = response.data.employee.dni
          this.morningCheckin = response.data.morningCheckin
          this.eveningCheckin = response.data.eveningCheckin
          this.salaryArray = response.data.salary
          this.sumarrays()
          this.generarReporte()
        })
        .catch(error => {

        })
    },

  },
}
</script>
